<template>
  <div
    ref="galleryRef"
    class="h-dynamic-screen fixed left-0 top-0 z-gallery flex w-full flex-col items-center bg-primary text-white lg:pb-0 landscape:pb-0 md:landscape:pb-14"
  >
    <div
      class="flex w-full items-end px-6 pt-4 landscape:absolute md:landscape:relative"
    >
      <button
        class="z-10 ml-auto hover:opacity-60"
        @click="$emit('close')"
      >
        <UiIcon
          name="close"
          width="14"
        />
      </button>
    </div>

    <div
      v-if="title || date || place"
      ref="galleryHeaderRef"
      class="flex flex-col gap-1 pb-4 text-center landscape:hidden md:landscape:block"
    >
      <h5 class="my-0 py-0 text-white">
        {{ title }}
      </h5>
      <div
        v-if="date || place"
        class="flex items-center justify-center gap-1 text-sm text-blue-100"
      >
        <time v-if="date">
          {{ date }}
        </time>
        <div v-if="date && place">
          |
        </div>
        <div v-if="place">
          {{ place }}
        </div>
      </div>
    </div>

    <div
      v-if="Array.isArray(media) && media.length"
      class="box-content flex max-h-full max-w-full items-center justify-center gap-4 px-4 lg:max-w-screen-lg"
      :style="computedSlideWrapperCss"
    >
      <Swiper
        v-bind="mainSwiperSettings"
        class="swiper-main size-full"
        :initial-slide="initialSlide"
        @slide-change="(swiper) => onSlideChange(swiper.activeIndex)"
      >
        <SwiperSlide
          v-for="(medium, index) in media"
          :key="medium?.publicUrl ?? `media-${index}`"
          class="!flex items-center justify-center px-8"
        >
          <div
            v-if="
              medium.type === 'iframe' || medium.properties?.type === 'video'
            "
            class="flex aspect-video h-auto max-h-full w-full max-w-full items-center justify-center lg:max-w-screen-lg"
          >
            <iframe
              class="aspect-video h-auto max-h-full w-full align-middle"
              :width="medium.properties?.dimensions?.width || 1280"
              :height="medium.properties?.dimensions?.height || 720"
              :src="getMediaUrl(medium)"
              frameborder="0"
              loading="lazy"
            />
          </div>
          <div
            v-else
            class="flex max-h-full w-auto max-w-full items-center justify-center pt-1 lg:max-w-screen-lg"
            :class="media.length > 1 ? 'h-full' : 'h-auto'"
          >
            <UiImage
              class="size-auto max-h-full align-middle"
              :src="medium.publicUrl"
              :width="medium.properties?.dimensions?.width"
              :height="medium.properties?.dimensions?.height"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>

    <div
      v-if="Array.isArray(media) && media.length > 1"
      ref="galleryFooterRef"
      class="absolute bottom-0 left-0 mt-auto flex w-full flex-col gap-4 py-4 landscape:hidden md:landscape:block"
    >
      <div class="text-center text-sm font-normal text-blue-100">
        {{ activeIndex + 1 }} / {{ media.length }}
      </div>
      <Swiper
        class="h-32 w-full !py-4"
        v-bind="thumbsSwiperSettings"
        :initial-slide="initialSlide"
        @swiper="onThumbsInit"
      >
        <SwiperSlide
          v-for="(medium, index) in media"
          :key="medium?.publicUrl ?? `media-footer-${index}`"
          class="aspect-gallery-thumbnail h-full overflow-hidden rounded-md"
        >
          <img
            v-if="medium.type === 'iframe' || medium.type === 'video'"
            class="size-full rounded-md object-cover"
            :src="medium.thumbnail"
            :width="360"
            :height="180"
          >
          <UiImage
            v-else
            class="size-full rounded-md object-cover"
            :src="medium.publicUrl"
            :width="360"
            :height="180"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { FreeMode, Mousewheel, Navigation, Thumbs } from 'swiper/modules'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import type { SwiperOptions } from 'swiper/types/swiper-options'
import type SwiperClass from 'swiper'
import UiImage from '../UiImage/UiImage.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import 'swiper/css'
import type { GalleryMedia, GalleryProps } from './UiGallery.types'

const props = withDefaults(defineProps<GalleryProps>(), {
  initialSlide: 0,
  title: '',
  date: '',
  place: '',
})
defineEmits(['close'])

const galleryFooterRef = ref<HTMLDivElement>()
const galleryHeaderRef = ref<HTMLDivElement>()
const galleryRef = ref<HTMLDivElement>()

const footerHeight = ref(0)
const headerHeight = ref(0)
const computedSlideWrapperCss = computed(() => {
  return `height: calc(100% - ${footerHeight.value}px - ${headerHeight.value}px)`
})

function updateSwiperHeight() {
  footerHeight.value
    = galleryFooterRef.value?.getBoundingClientRect().height ?? 0
  headerHeight.value
    = galleryHeaderRef.value?.getBoundingClientRect().height ?? 0
}

watch([galleryFooterRef, galleryHeaderRef], updateSwiperHeight)

const resizeObserver = new ResizeObserver(updateSwiperHeight)
onMounted(() => {
  resizeObserver.observe(galleryRef.value)
  disableBodyScroll(galleryRef.value, {
    reserveScrollBarGap: true,
  })
})

onUnmounted(() => {
  clearAllBodyScrollLocks()
})

const thumbsSwiperInstance = ref<SwiperClass>()
const activeIndex = ref(0)

const mainSwiperSettings = computed((): SwiperOptions => {
  return {
    enabled: props.media.length > 1,
    slidesPerView: 1,
    spaceBetween: 15,
    modules: [Navigation, Thumbs],
    navigation: true,
    loop: false,
    thumbs: { swiper: thumbsSwiperInstance.value },
    effect: 'fade',
  }
})

const thumbsSwiperSettings: SwiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 20,
  watchOverflow: true,
  slideToClickedSlide: true,
  watchSlidesProgress: true,
  centeredSlides: true,
  effect: 'fade',
  modules: [Mousewheel, FreeMode],
  mousewheel: true,
  freeMode: true,
  thumbs: {
    multipleActiveThumbs: false,
  },
}

function onSlideChange(index: number) {
  activeIndex.value = index
  requestAnimationFrame(() => {
    thumbsSwiperInstance.value.slideTo(index)
  })
}

function onThumbsInit(swiperInstance: SwiperClass) {
  thumbsSwiperInstance.value = swiperInstance
  swiperInstance.once('realIndexChange', () =>
    onSlideChange(props.initialSlide))
}

function getMediaUrl(media: GalleryMedia): string {
  if (!media.publicUrl) {
    return ''
  }

  if (media?.properties?.mimeType === 'video/youtube') {
    const separator = '&'
    const firstParamPrefix = media.publicUrl.includes('?') ? '&' : '?'

    const params = [
      'autoplay=1',
    ]

    return media.publicUrl + encodeURI(firstParamPrefix + params.join(separator))
  }
  return media.publicUrl
}
</script>

<style lang="postcss" scoped>
.swiper-main {
  --swiper-navigation-color: currentColor;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-size: 14px;
  padding-bottom: 0 !important;

  &:deep() {
    .swiper-button-prev,
    .swiper-button-next {
      @apply bg-white;
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  iframe {
    @media (min-width: 1024px) {
      width: 960px;
      height: 540px;
    }
  }
}

.swiper-thumbs {
  .swiper-slide {
    width: auto;
    height: auto;
  }
  .swiper-slide-thumb-active {
    transform: scale(1.2);

    img,
    iframe {
      @apply border-2 border-blue-450;
    }
  }
}
</style>
